import React from 'react';

import { HeadingXL, Layout, SEO } from '../components';

const NotFoundPage = () => (
  <Layout>
    <SEO title={404} />
    <div>
      <h2>Oh No! This page appears to have been kidnapped by Aliens</h2>
      <div>Beware the Anal Probe!</div>
    </div>
  </Layout>
);

export default NotFoundPage;
